import './product-teaser-carousel.scss';

import Swiper from 'swiper/bundle';
class ProductCarousel {
    constructor (element) {
        this.$productCarousel = element;
        this.$productCarouselSlider = this.$productCarousel.querySelector('[data-product-carousel="slider"]');
        this.$productCarouselSliderPagination = this.$productCarousel.querySelector('[data-product-carousel="pagination"]');
        this.$productCarouselSliderPrev = this.$productCarousel.querySelector('[data-product-carousel="prev"]');
        this.$productCarouselSliderNext = this.$productCarousel.querySelector('[data-product-carousel="next"]');
        this.initialize();
    }

    initialize () {
        this.initCarouselSlider();
    }

    // load slider
    initCarouselSlider () {
        if (this.$productCarouselSlider) {
            this.$productCarouselSlider = new Swiper(this.$productCarouselSlider, {
                autoHeight: false,
                height: 'auto',
                slidesPerView: 'auto',
                slidesPerGroup: 1,
                speed: 500,
                navigation: {
                    nextEl: this.$productCarouselSliderNext,
                    prevEl: this.$productCarouselSliderPrev
                },
                pagination: {
                    el: this.$productCarouselSliderPagination,
                    clickable: true,
                    dynamicBullets: true,
                    dynamicMainBullets: 1
                },
                breakpoints: {
                    768: {
                        slidesPerGroup: 2
                    },
                    1024: {
                        slidesPerGroup: 3,
                        pagination: {
                            dynamicBullets: false
                        }
                    },
                    1440: {
                        slidesPerGroup: 4,
                        pagination: {
                            dynamicBullets: false
                        }
                    }
                },
                on: {
                    breakpoint: (swiper) => {
                        if (swiper.params.pagination.dynamicBullets) {
                            swiper.pagination.init();
                        } else {
                            setTimeout(() => {
                                swiper.pagination.el.style.width = '100%';
                                swiper.pagination.el.classList.remove('swiper-pagination-bullets-dynamic');
                            });
                        }
                    }
                }
            });
        }
    }
}

export { ProductCarousel };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $productTeaserCarousel = $context.querySelectorAll('[data-product-carousel="root"]');

        if ($productTeaserCarousel.length > 0) {
            for (let i = 0; i < $productTeaserCarousel.length; i++) {
                const productTeaserCarousel = new ProductCarousel($productTeaserCarousel[i]);
                productTeaserCarousel.initialize();
            }
        }
    }
});
